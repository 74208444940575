<template>
  <div>
    <h4>Add Academy Section</h4>
    <form @submit.prevent="submit" v-if="! loading">
      <div class="form-group">
        <label>Section Title</label>
        <input
          type="text"
          placeholder="Section Title"
          v-model="section.title"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Sort Order</label>
        <input
          type="text"
          placeholder="Sort Order"
          v-model="section.sort"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>About this section</label>
        <editor
          api-key="no-api-key"
          v-model="section.about"
          :init="{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
          }"
        />
      </div>
      <div class="form-group">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>

    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      section: {},
      editor_options: {
        height: "500px"
      },
      loading: false
    };
  },

  methods: {
    submit() {
      this.loading = true

      this.$axios
        .post("/api/v1/admin/academy/sections", this.section)
        .then((response) => {
          this.loading = false
          this.$router.push({
            name: 'admin.academy.show',
            params: { id: response.data.id }
          });
        });
    },
  },
};
</script>